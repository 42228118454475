// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beatrice-and-benedick-spotted-js": () => import("./../../../src/pages/beatrice-and-benedick-spotted.js" /* webpackChunkName: "component---src-pages-beatrice-and-benedick-spotted-js" */),
  "component---src-pages-claudio-on-finding-new-joy-js": () => import("./../../../src/pages/claudio-on-finding-new-joy.js" /* webpackChunkName: "component---src-pages-claudio-on-finding-new-joy-js" */),
  "component---src-pages-claudio-the-wounded-soldier-js": () => import("./../../../src/pages/claudio-the-wounded-soldier.js" /* webpackChunkName: "component---src-pages-claudio-the-wounded-soldier-js" */),
  "component---src-pages-claudios-lament-js": () => import("./../../../src/pages/claudios-lament.js" /* webpackChunkName: "component---src-pages-claudios-lament-js" */),
  "component---src-pages-hero-alive-and-well-not-dead-js": () => import("./../../../src/pages/hero-alive-and-well-not-dead.js" /* webpackChunkName: "component---src-pages-hero-alive-and-well-not-dead-js" */),
  "component---src-pages-hero-or-villain-js": () => import("./../../../src/pages/hero-or-villain.js" /* webpackChunkName: "component---src-pages-hero-or-villain-js" */),
  "component---src-pages-hero-vindicated-js": () => import("./../../../src/pages/hero-vindicated.js" /* webpackChunkName: "component---src-pages-hero-vindicated-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-the-best-dirt-from-the-best-party-of-the-year-js": () => import("./../../../src/pages/the-best-dirt-from-the-best-party-of-the-year.js" /* webpackChunkName: "component---src-pages-the-best-dirt-from-the-best-party-of-the-year-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

